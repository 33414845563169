.order-submit {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.form {
	min-height: 500px;
}
